@import "primeflex/primeflex.scss";

.max-h-90 {
  max-height: 90vh;
}

// applied to content in popup dialogs that configure
// the containing element using host
.flex-content {
  flex: auto;
  overflow-y: auto;
  max-height: 90vh;
}

// applied to the templates in p-dialog
.p-dialog-header {
  @include styleclass("shadow-2");
  background-color: var(--surface-200) !important;
  padding: 12px !important;
  z-index: 15;
}

.p-dialog-content {
  padding: 0 !important;
}

.p-dialog-footer {
  padding: 0px !important;
}  

// applied when drawing the header
.dialog-header {
  background-color: var(--surface-200);
  @include styleclass("shadow-2");
  z-index: 5;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  width: 100%;
}  

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px;
  background-color: var(--surface-50);
  border-top: 1px solid var(--surface-200);
}
