@import "popup";
@import "storage";
@import "overrides";

// borders - mainly for layout testing
.bordr {
  border: 1px solid darkorange;
}
.bordp {
  border: 1px solid #b150c5;
}
.bordg {
  border: 1px solid #78be21;
}

// two-tone gray icons
.gray {
  filter: invert(98%) sepia(85%) saturate(163%) hue-rotate(186deg) brightness(112%) contrast(88%);
  // filter: invert(93%) sepia(1%) saturate(751%) hue-rotate(347deg) brightness(82%) contrast(87%);
  // filter: invert(95%) sepia(0%) saturate(348%) hue-rotate(137deg) brightness(93%) contrast(98%);
}

// short-hand for a row of vertically centered elements
.flex-row {
  display: flex;
  align-items: center;
  gap: 8px;
}

.p-breadcrumb {
  font-weight: 700 !important;
  padding: 4px 8px 8px 20px !important;
  border: none !important;
}

.h64 {
  width: 80px;
  border: 2px solid #ffffff40;
  border-radius: 4px;
}

.w0 {
  width: 0;
}

// .tabview-custom {
//   i, span {
//     vertical-align: middle;
//     margin-right: 0.3rem;
//   }
// }

.topmenu-button {
  display: flex;
  align-items: center;
  &:hover {
    background-color: #00000010;
  }
  border-radius: 12px;
  cursor: pointer;
}

.bg-a {
  background-color: var(--bg-a);
}

.bg-b {
  background-color: var(--bg-b);
}

.bg-c {
  background-color: var(--bg-c);
}

.bg-d {
  background-color: var(--bg-d);
}

.bg-e {
  background-color: var(--bg-e);
}

.bg-f {
  background-color: var(--bg-f);
}

.bg-g {
  background-color: var(--bg-g);
}

.menu-sepr {
  margin: 6px 0;
  border-top: 1px solid #ffffff20;
}

.vtab2 {
  max-height: calc(100% - 60px); /* Adjust as needed */
  overflow-y: auto;
}

// define global variables for specific iProof style

:root {
  --skip56: 56px;
}
