$skip: 62px;
$menuWidth: 68px;

//* _content.scss

.layout-main-container {
  padding: ($skip - 6px) 0 0 0;
}

//* _menu.scss

.layout-sidebar {
  width: $menuWidth;
  height: calc(100vh - $skip + 5px);
  top: $skip - 5px;
  left: 0;
  padding: 4px 4px;
  border-radius: 0;

  background: linear-gradient(
    to bottom,
    var(--bg-c),
    var(--bg-e)
  );
}


.layout-menu {
  .layout-root-menuitem {
    > .layout-menuitem-root-text {
      text-transform: none;
      font-weight: normal;
      margin: 0.75rem 0;
    }
  }
  ul {
    a {
      border-radius: 0;
      &:hover {
        background-color: var(--surface-hover);
      }
    }
  }
}

//* _responsive.scss

@media screen and (min-width: 1960px) {
  .layout-main,
  .landing-wrapper {
    width: 1960px;
  }
}

@media (min-width: 992px) {
  .layout-wrapper {
    &.layout-overlay {
      .layout-main-container {
        padding-left: 0;
      }
    }
    &.layout-static {
      .layout-main-container {
        margin-left: $menuWidth + 2px;
      }

      &.layout-static-inactive {
        .layout-main-container {
          padding-left: 0;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .layout-wrapper .layout-main-container {
    padding-left: 0;
  }
  .layout-topbar {
    height: 4rem;
  }
  .layout-topbar .layout-topbar-logo {
    img {
      margin: 0;
    }
  }
}

//* _topbar.scss

@media (min-width: 992px) {
  .layout-topbar {
    height: 56px;
    padding: 0 14px 0 0;

    .layout-topbar-logo {
      display: flex;
      width: $menuWidth;
      justify-content: center;

      img {
        height: 40px;
        margin: 0;
      }
    }
  }
}

//* _menu.scss

.layout-menu {
  margin-top: 0em;

  ul {
    a {
      .layout-menuitem-icon {
        font-size: 24px;
        align-content: center;
        margin: 0 0 6px;
        color: var(--bg-x);
        // color: var(--surface-100);
      }
      &.active-route {
        font-weight: 700;
        // color: #ffff80dd !important;
      }
    }

    .menu-stack {
      color: var(--bg-y);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      width: 100%;
    }
  }
}

//* other

.p-dialog-header {
  height: 45px;
}

.layout-menuitem-text {
  color: inherit;
}

.menu-separator {
  border-top: 1px solid #00000080;
  height: 16px;
}

// .p-inputtext {
//   height: 40px !important;
// }

// .p-dropdown .p-inputtext {
//   padding-top: 11px !important;
// }
