
//for primeflex grid system
$gutter: 1rem;

// Layout
@import "assets/layout/styles/layout/layout.scss";

// PrimeNG
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

// prism theme
@import "../node_modules/prismjs/themes/prism-coy.css";

// iproof styles
@import "./assets/iproof/iproof.scss";

// i {
//   font-family: "Material Icons Two Tone", "Material Icons";
//   filter: invert(65%) sepia(62%) saturate(5467%) hue-rotate(250deg) brightness(79%) contrast(93%);
// }
.primary {
  filter: invert(17%) sepia(37%) saturate(4207%) hue-rotate(274deg) brightness(90%) contrast(96%);
}
.primary-light {
  filter: invert(65%) sepia(62%) saturate(5467%) hue-rotate(250deg) brightness(79%) contrast(93%);
}
.primary-dark {
  filter: invert(9%) sepia(41%) saturate(6104%) hue-rotate(214deg) brightness(94%) contrast(102%);
}
.accent {
  filter: invert(38%) sepia(70%) saturate(576%) hue-rotate(327deg) brightness(90%) contrast(94%);
}
// .accent-light {
//   filter: invert(47%) sepia(97%) saturate(1627%) hue-rotate(12deg) brightness(101%) contrast(98%);
// }

.teal {
  filter: invert(40%) sepia(97%) saturate(2839%) hue-rotate(150deg) brightness(89%) contrast(102%);
}

.float-right {
  float: right;
}

.p-treenode-content {
  padding: 0 !important;
}
.p-treenode-children {
  padding-left: 10px !important;
}
.p-treenode {
  padding: 0 !important;
}
.p-tree-toggler {
  margin-right: 0px !important;
}