// styles for the storage module

.p-tree {
  padding: 4px !important;
}

.popin {
  visibility: hidden;
  opacity:0;
  transition: visibility 0.1s linear, opacity 0.25s linear;
}

.p-treenode-label {
  width: 100%;
  :hover .popin {
    visibility: visible;
    opacity:1;
  }
}

.p-treenode-content {
  cursor: default !important;
  i {
    cursor: pointer;
  }
}

.storage-menu {
  border: none;
  padding: 4px;
  .p-menuitem-link {
    padding: 8px 10px !important;
  }
  background-color: var(--surface-50);
  // @include styleclass("shadow-2");
  // z-index: 100;
}

.p-tabview-panels {
  padding: 0 !important;
  margin: 0 !important;
}

.p-datatable-wrapper {
  min-height: 100% !important;
}